.scroll{
    opacity: 0 !important;
    filter: blur(5px) !important;
    transition: all 1s !important;
}

.scrollRight{
    transform: translateX(100%) !important;
}

.scrollLeft{
    transform: translateX(-50%) !important;
}

.scrollDown{
    transform: translateY(50%) !important;
}

.show{
    opacity: 1 !important;
    filter: blur(0) !important;
    transform: translateX(0) !important;
}

@media(prefers-reduced-motion: reduce){
    .scroll{
        transition: none;
    }
}