.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

::-webkit-scrollbar{
  width: 10px;
}

::-webkit-scrollbar-track{
  background: transparent;
}

::-webkit-scrollbar-thumb{
  background: #fea905;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover{
  background: #ff9500;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;600;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@1,200&display=swap');

@font-face {
  font-family: Revue;
  src: url('./fonts/RevueBT-Regular.otf');
}

.sc-hKMtZM {
  color: orange !important;
  background-color: white !important;
  font-style: italic !important;
}

.rsc-os-options {
  margin-left: 35px !important;
}

.rsc-header-title:before {
  content: 'Expert Educational '
}

.rsc-header-title:after {
  content: ' Bot'
}

.rsc-header-title {
  font-family: 'Nunito Sans' !important;
  font-weight: 600 !important;
}

.rsc-container {
  border-radius: 0px !important;
}

.rsc-input {
  font-family: 'Source Code Pro', monospace;
  color: #fea905 !important;
  font-weight: 700;
  letter-spacing: 2px;
}


.MuiSelect-outlined {
  border: 0px solid orange !important;
  padding: 5px !important;
  outline: none !important;
}

.MuiSelect-icon {
  color: #fea905 !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  color: skyblue;
  cursor: pointer;
  height: auto;
  position: absolute;
  height: 22px;
  left: 275px;
  width: 120px;
}

input::file-selector-button {
  font-family: Nunito Sans;
  font-weight: 1000;
  background: white;
  color: black;
  border-radius: 10px;
  transition: all 0.5s ease;
  border: 2px solid orange;
  width: 150px;
  padding: 5px;
}

input::file-selector-button:hover,
input::file-selector-button:focus {
  background: linear-gradient(to right bottom, black, #0411af);
  color: #fea905;
  box-shadow: 0px 0px 6px 2.5px #fea905 ,1px 1px 1px 0px #ECDE65,1px 1px 1px 0px #ECDE65;
}

label[for=avatar] {
  font-family: Nunito Sans;
  font-weight: 1000;
  background: white;
  color: black;
  border-radius: 10px;
  transition: all 0.5s ease;
  border: 2px solid orange;
  width: 150px;
  padding: 7px 7px 7px 18px;
  margin-bottom: 6px;
}

label[for=avatar]:hover,label[for=avatar]:focus{
  background: linear-gradient(to right bottom, black, #0411af);
  color: #fea905;
  box-shadow: 0px 0px 6px 2.5px #fea905 ,1px 1px 1px 0px #ECDE65,1px 1px 1px 0px #ECDE65;
}

.menu-list{
  background:white;
  color:#fea905;
  padding:10px;
  border-radius:5px;
  font-size: 17px;
  border:2px solid #fea905;
}

.menu-list li:hover, .menu-list li:focus,.menu-list-drawer li:focus{
  background:rgba(254, 185, 1, 0.2);
  color:black;
}

.menu-list-drawer{
  color:#fea905;
  border-radius:5px;
  font-size: 17px;
  padding: 10px 0px 0px 0px;
}
